import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { first, reduce, keys, noop } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Segment, { SegmentConfig } from '@sparefoot/segment-react';

import { googleAutocompleteSelector } from 'store/selectors/search';
import { on } from 'utils/dom/dom';
import { Input } from 'components/core/Input';
import { getSegmentContext } from 'components/core/library/segment';
import GoogleMapsAutocomplete from 'lib/googleMaps/autocomplete';

export const enhance = compose(
	connect(googleAutocompleteSelector),
	getSegmentContext
);

export function formatPlaceObject(place) {
	const params = {};

	if (place) {
		const addressComponents = reduce(place.address_components, (components, c) => ({
			...components,
			[first(c.types)]: c.short_name
		}), {});

		params.city = addressComponents.locality;
		params.zip = addressComponents.postal_code;
		params.state = addressComponents.administrative_area_level_1;
		params.locationSource = 'google_autocomplete';

		if (place.geometry) {
			params.latitude = place.geometry.location.lat();
			params.longitude = place.geometry.location.lng();
		}
	}
	return params;
}

// TODO: recompose
export class GoogleAutocomplete extends PureComponent {
	static propTypes = {
		segmentLabel: PropTypes.string.isRequired,
		/** func onChange({location, city, zip, state, locationSource}) */
		onChange: PropTypes.func,
		value: PropTypes.string,
		segmentConfig: PropTypes.object,
		getRef: PropTypes.func,
		googleMapsKey: PropTypes.string,
		hideOnScroll: PropTypes.bool // sf_11267_sticky_search_clp_hero
	};

	static defaultProps = {
		onChange: noop,
		value: '',
		hideOnScroll: false // sf_11267_sticky_search_clp_hero
	};

	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			loadedAutocomplete: false
		};
	}

	// sf_11267_sticky_search_clp_hero
	componentDidMount() {
		if (this.props.hideOnScroll) {
			on('scroll', (e) => {
				e.preventDefault();
				const GoogleAutocompleteDrawerEl = document.querySelector('.pac-container');
				if (
					GoogleAutocompleteDrawerEl &&
					GoogleAutocompleteDrawerEl.style.display !== 'none' &&
					this.textInput === document.activeElement
				) {
					GoogleAutocompleteDrawerEl.style.display = 'none';
				}
			});
		}
	}

	componentWillUnmount() {
		GoogleMapsAutocomplete.removeAutocomplete(this.autoComplete);
		this.autoComplete = null;
	}

	_onFocus = () => {
		if (!this.state.loadedAutocomplete) {
			GoogleMapsAutocomplete.configureAutocomplete(this.textInput, this._onAutocomplete, this.props.googleMapsKey)
			.then((autoComplete) => {
				this.autoComplete = autoComplete;
			});
			this.setState({ loadedAutocomplete: true });
		}
	}

	_onChange = (evt) => {
		const { value } = evt.target;

		this.setState({ value });
		this.props.onChange({ location: value });
	};

	_onAutocomplete = (value, place) => {
		this.setState({ value });

		const locationParams = formatPlaceObject(place);
		this.props.onChange({
			...locationParams,
			location: value
		});

		if (keys(place).length > 1) {
			const segmentProps = SegmentConfig.buildTrackingProps(
				this.props.segmentConfig,
				{ segmentLabel: this.props.segmentLabel }
			);
			Segment.trackClick(segmentProps);
		}
	};

	_buildProps() {
		const props = {
			getRef: (ref) => {
				this.textInput = ref;
				if (this.props.getRef) {
					this.props.getRef(ref);
				}
			},
			onChange: this._onChange,
			onFocus: this._onFocus
		};

		return ({
			...this.props,
			...props
		});
	}

	render() {
		return (
			<Input
				placeholder=""
				value={this.state.value}
				{...this._buildProps()}
			/>
		);
	}
}

export default enhance(GoogleAutocomplete);
