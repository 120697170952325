import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'components/core/Image';
import { H2, Paragraph } from 'components/core/Typography';

import './SizeGuideSection.scss';

function SizeGuideCard({ title, range, icon, sections }) {
	return (
		<ul className="size-guide-card">
			<li className="sg-card-header" key={title}>
				<div className="sg-card-title-left">
					<H2 size="medium" weight="standard" color="black">
						<strong>{`${title} Units`}</strong>
					</H2>
					<div className="sg-range">
						<strong>{range}</strong>
						{' SQ FT'}
					</div>
				</div>
				<div className="sg-card-title-right">
					<div className="sg-image">
						<Image
							src={icon}
							alt="illustration"
							className="truck-illustration"
						/>
					</div>
				</div>
			</li>
			{sections.map((section) => (
				<li key={section.title}>
					<Paragraph last color="black" weight="ultraBold">
						{section.title}
					</Paragraph>
					<Paragraph size="small" weight="standard" color="black">
						{section.content}
					</Paragraph>
				</li>
			))}
		</ul>
	);
}

SizeGuideCard.propTypes = {
	title: PropTypes.string,
	range: PropTypes.string,
	icon: PropTypes.string,
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.string
		})
	)
};

export default SizeGuideCard;
