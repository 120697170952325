import { mapProps } from 'recompose';
import { Image } from 'components/core/Image';

import lockerImage from 'assets/images/unit-icons/locker.svg';
import wineImage from 'assets/images/unit-icons/wine.svg';
import outdoorImage from 'assets/images/unit-icons/outdoor.svg';
import workspaceImage from 'assets/images/unit-icons/workspace.svg';

import storage5x5 from 'assets/images/unit-icons/5x5-storage.svg';
import storage5x10 from 'assets/images/unit-icons/5x10-storage.svg';
import storage5x15 from 'assets/images/unit-icons/5x15-storage.svg';
import storage10x10 from 'assets/images/unit-icons/10x10-storage.svg';
import storage10x15 from 'assets/images/unit-icons/10x15-storage.svg';
import storage10x20 from 'assets/images/unit-icons/10x20-storage.svg';
import storage10x30 from 'assets/images/unit-icons/10x30-storage.svg';
import parkingSpace10x15 from 'assets/images/unit-icons/10x15-parking_space.svg';
import parkingSpace10x20 from 'assets/images/unit-icons/10x20-parking_space.svg';
import parkingSpace10x25 from 'assets/images/unit-icons/10x25-parking_space.svg';
import parkingSpace10x30 from 'assets/images/unit-icons/10x30-parking_space.svg';
import parkingSpace10x35 from 'assets/images/unit-icons/10x35-parking_space.svg';
import parkingSpace10x40 from 'assets/images/unit-icons/10x40-parking_space.svg';
import parkingSpace10x50 from 'assets/images/unit-icons/10x50-parking_space.svg';
import boat10x15 from 'assets/images/icons/boat-storage/10x15--boat-space.svg';
import boat10x20 from 'assets/images/icons/boat-storage/10x20--boat-space.svg';
import boat10x25 from 'assets/images/icons/boat-storage/10x25--parking-space.svg';
import boat10x30 from 'assets/images/icons/boat-storage/10x30--boat-space.svg';
import boat10x35 from 'assets/images/icons/boat-storage/10x35--boat-space.svg';
import boat10x40 from 'assets/images/icons/boat-storage/10x40--boat-space.svg';
import boat10x50 from 'assets/images/icons/boat-storage/10x50--boat-space.svg';

import './unitIcon.scss';

const icons = {
	'5x5-storage': storage5x5,
	'5x10-storage': storage5x10,
	'5x15-storage': storage5x15,
	'10x10-storage': storage10x10,
	'10x15-storage': storage10x15,
	'10x20-storage': storage10x20,
	'10x30-storage': storage10x30,
	'5x5-parking_space': parkingSpace10x15,
	'10x15-parking_space': parkingSpace10x15,
	'10x20-parking_space': parkingSpace10x20,
	'10x25-parking_space': parkingSpace10x25,
	'10x30-parking_space': parkingSpace10x30,
	'10x35-parking_space': parkingSpace10x35,
	'10x40-parking_space': parkingSpace10x40,
	'10x50-parking_space': parkingSpace10x50,
	'5x5-boat': boat10x15,
	'10x15-boat': boat10x15,
	'10x20-boat': boat10x20,
	'10x25-boat': boat10x25,
	'10x30-boat': boat10x30,
	'10x35-boat': boat10x35,
	'10x40-boat': boat10x40,
	'10x50-boat': boat10x50
};

function chooseIconFile(unitSizeText) {
	if (unitSizeText.match('locker')) {
		return lockerImage;
	}
	if (unitSizeText.match('wine')) {
		return wineImage;
	}
	if (unitSizeText.match('outdoor')) {
		return outdoorImage;
	}
	if (unitSizeText.match('workspace')) {
		return workspaceImage;
	}

	return icons[unitSizeText];
}

const enhance = mapProps((props) => ({
	className: 'unit-icon',
	src: chooseIconFile(props.unitSize),
	lazyLoad: false
}));


export default enhance(Image);
