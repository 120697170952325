import React from 'react';
import { withSegmentContext } from 'components/core/library/segment';
import { H3, Paragraph } from 'components/core/Typography';
import { Image } from 'components/core/Image';
import { Link } from 'components/core/Link';
import { Stars } from 'components/review/Stars';
import { Review } from 'components/brand/ReviewSection';

import ReviewChatBubble from 'assets/images/homepage/review-chat-bubble.svg';

import './ReviewSection.scss';

const enhance = withSegmentContext;

const reviews = [
	{
		reviewTitle: 'Great experience and saved a lot of $$$',
		reviewRating: 5,
		// eslint-disable-next-line max-len
		reviewText: 'A friend told me about SpareFoot and how easy it was to use and how much money they saved - they were right! I found a super deal at a great storage facility and the whole transaction was very smooth. Highly recommended!',
		reviewerInitials: 'CJ',
		reviewerName: 'Cameron Johnson',
		reviewDate: 'January 3rd, 2023'
	},
	{
		reviewTitle: 'I love, love, love, this site!!!',
		reviewRating: 5,
		// eslint-disable-next-line max-len
		reviewText: 'I love, love, love, this SpareFoot site. I will recommend it to all who are looking for a storage site that does it all. Moving is such a hectic experience and SpareFoot takes away a lot of the anxiety because it gives me choices for storage that would otherwise take a lot of time and searching. Thank you for the help when I needed it.',
		reviewerInitials: 'PM',
		reviewerName: 'Patricia Manning',
		reviewDate: 'October 16th, 2023'
	},
	{
		reviewTitle: 'The reservation was easy and smooth.',
		reviewRating: 4,
		// eslint-disable-next-line max-len
		reviewText: 'The reservation was easy and smooth. I asked the facility about truck rentals. Their answer was they don\'t provide them or have any information about rental trucks. Sparefoot gave a link to reserve moving truck in my confirmation. I would like to receive a referral or at least information that I can rent a truck through Spare Foot from every facility that has relationships with Spare Foot. I would also like to have my reservation application saved when I have to leave it for a while, rather than start over.',
		reviewerInitials: 'CS',
		reviewerName: 'Cheryl Sanders',
		reviewDate: 'February 1st, 2024'
	},
	{
		reviewTitle: 'You won\'t go wrong with SpareFoot!!!',
		reviewRating: 5,
		// eslint-disable-next-line max-len
		reviewText: 'They were very accommodating on making sure that all my needs were accomplished and satisfied for my moving needs and also gave me the best quotes and options and were also very pleasant and easy to talk with. And also found me the most convenient location not far from where my move is taking place. I would highly recommend these facilities or SpareFoot to anyone who\'s in need. Very pleasant and experienced to work with.',
		reviewerInitials: 'MS',
		reviewerName: 'Mark Solheim',
		reviewDate: 'October 18th, 2023'
	}
];

export function ReviewSection() {
	return (
		<div className="reviews-section">
			<div className="section-content">
				<div className="reviews-intro">
					<Image src={ReviewChatBubble} alt="illustration" />
					<H3 weight="standard" color="white" family="rounded">
						Sparefoot Customer Reviews
					</H3>
					<Paragraph weight="standard" color="white">
						See what some of our
						<span className="sf-type-yellow sf-type-weight-bold "> over one million customers </span>
						have to say about us.
					</Paragraph>
				</div>
				<div className="reviews-content">
					{reviews.map((review) => (
						<Review {...review} key={review.reviewerInitials} />
					))}
				</div>
				<Link
					href="http://www.trustpilot.com/review/www.sparefoot.com"
					target="_blank"
					rel="noopener noreferrer"
					segmentLabel="trustpilot outbound"
					hideBorder
				>
					<Stars rating={4.4} outOf={5} size="medium" />
					SpareFoot has a 4.3 out of 5 star rating from over 41,000 customer reviews on Trustpilot
				</Link>
			</div>
		</div>
	);
}

export default enhance(ReviewSection);
