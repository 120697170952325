import React from 'react';
import { Image } from 'components/core/Image';
import { H2, Paragraph } from 'components/core/Typography';
import { Link } from 'components/core/Link';
import { Text } from 'components/core/Text';

import fiveByFive from 'assets/images/icons/size-guide/5x5.svg';
import tenByTen from 'assets/images/icons/size-guide/10x10.svg';
import tenByThirty from 'assets/images/icons/size-guide/10x30.svg';
import arrowIcon from 'assets/images/icons/size-guide/arrow.svg';
import SizeGuideCard from './SizeGuideCard';

import './SizeGuideSection.scss';

const sizeGuideCards = [
	{
		title: 'Small',
		icon: fiveByFive,
		range: '25 to 75',
		sections: [
			{
				title: 'Common unit sizes:',
				content: '5’ x 5’, 5’ x 10’, 5’ x 15’'
			},
			{
				title: 'Looks like:',
				content: 'Anywhere from a hall closet or half bathroom to a small bedroom depending on the storage unit size.'
			},
			{
				title: 'What will fit:',
				// eslint-disable-next-line max-len
				content: 'Small items, like bags, suitcases and boxes to the contents of a small-one bedroom apartment.'
			}
		]

	},
	{
		title: 'Medium',
		icon: tenByTen,
		range: '75 to 200',
		sections: [
			{
				title: 'Common unit sizes:',
				content: '10’ x 10’, 10’ x 15’, 10’ x 20’'
			},
			{
				title: 'Looks like:',
				content: 'Dependent on the unit size, medium sized units look like an average bedroom to a one car garage.'
			},
			{
				title: 'What will fit:',
				// eslint-disable-next-line max-len
				content: 'Ranges from the contents of a one-bedroom apartment to the contents of a two-to-three bedroom house.'
			}
		]
	},
	{
		title: 'Large',
		icon: tenByThirty,
		range: '200 to 300',
		sections: [
			{
				title: 'Common unit sizes:',
				content: '10’ x 25’, 10’ x 30’'
			},
			{
				title: 'Looks like:',
				content: 'A one car garage to a two car garage depending on the unit size.'
			},
			{
				title: 'What will fit:',
				// eslint-disable-next-line max-len
				content: 'Ranges from the contents of a three-bedroom house to a four to five bedroom house with appliances.'
			}
		]
	}
];

function SizeGuideSection() {
	return (
		<div className="section-container size-guide-section">
			<div className="section-content">
				<div className="sg-section-header">
					<div className="sg-section-header-left">
						<H2
							size="semiLarge"
							weight="medium"
							color="black"
							family="rounded"
							className="sg-title-left"
						>
							Self-Storage Size Guide
						</H2>
						<Paragraph
							size="small"
							weight="standard"
							color="black"
						>
							What size storage unit do you need?
						</Paragraph>
					</div>
					<div className="sg-section-header-right">
						<Link
						// eslint-disable-next-line max-len
							href="/storage-unit-size-guide.html"
							segmentLabel="storage unit size guide"
						>
							<Text size="small">Picking the right size storage unit</Text>
							<span>
								<Image
									src={arrowIcon}
									alt="arrow icon"
									className="arrow-icon"
									lazyLoad={false}
								/>
							</span>
						</Link>
					</div>
				</div>
				<div className="size-guide-cards">
					{sizeGuideCards.map((item) => (
						<SizeGuideCard {...item} key={item?.title} />
					))}
				</div>
			</div>
		</div>
	);
}

export default SizeGuideSection;
